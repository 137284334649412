// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "button";
@import "table";
@import "form";
@import "modal";
@import "scroll";

:root {
  --grey1: #F0F0F0;
  --grey2: #C9C9C9;
  --grey3: #AFB6BE;
  --grey4: #F8F8F8;
  --grey5: #7A7A7A;
  --grey6: #545454;
  --grey7: #E3E3E3;
  --grey8: #505D74;
  --grey9: #1D283A;

  --black: #000000;
  --white: #ffffff;
  --red1: #E2291C;
  --blue3: #00719C;
  --blue4: #00517C;
  --blue5: #0571E6;
  --green1: #009731;
  --orange1: #FF9800;
  --orange2: #FF6600;
  --yellow: #FFED47;

}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 20px;
}

.hidden {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.switch_btn {
  width: 28px;
  height: 16px;
  display: inline-block;
  border: 1px solid var(--grey3);
  border-radius: 8px;

  &.disabled {
    opacity: 0.5;
  }
}

.switch_btn_active {
  background-color: rgb(0, 113, 156);
}

.switch_circle {
  border: 0 solid grey;
  border-radius: 8px;
  height: 12px;
  width: 12px;
  left: 1px;
  top: 1px;
  background-color: var(--grey3);
  position: relative;
  transition-duration: 0.15s;
}

.switch_circle_active {
  left: 13px;
  background-color: white;
}

.styled-checkbox {
  position: absolute;
  left: -10000000px;
  height: 0;
  width: 0;
}

.navbar-nav .active {
  text-decoration: underline;
}

.swal-button {
  background-color: #28a745;
}

.swal-button:hover {
  background-color: #218838 !important;
}

.swal-button:active {
  background-color: #218838 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.table-loading {
  position: relative;
}

.table-loading:before {
  position: absolute;
  top: 50px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 50px);
  content: "";
  background-image: url("../images/preloader.svg");
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

h1 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--black);
}

.tab-header__container {
  position: relative;
  flex-grow: 1;

  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    // background: #f2f2f2;
    background: #ccc;
    border-radius: 2px;
  }
}

.tab-header__item {
  padding-bottom: 12px;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
  position: relative;

  &.active {
    color: #1c1c1c;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      background: #00719c;
      left: 0;
      bottom: 0;
      z-index: 1;
      border-radius: 2px;
    }
  }
}

.text-muted {
  font-weight: 400;
  //font-size: 14px;
  //line-height: 16px;
  color: #212529 !important;
}

.text-grey6 {
  color: var(--grey6);
}

.text-grey5 {
  color: var(--grey5);
}

.bg-grey7 {
  background-color: var(--grey7);
}

.bg-grey2 {
  background-color: var(--grey2) !important;
}

.text-danger {
  font-size: 11px;
  position: absolute;
  //bottom: 0;
  left: 0;
  //bottom:0;
}

.text-orange {
  font-size: 11px;
  position: absolute;
  //bottom: 0;
  left: 0;
  color: #FF6600;
}

@media (min-width: 500px) {
  .d-sm-100 {
    width: 100%;
  }
}

.sidebar {
  width: 48px;
  position: fixed;
  left: 0;
  background: #354052;
  transition: width 0.6s;
  min-height: 100vh;
  z-index: 11;

  & .sidebar-text {
    opacity: 0;
    position: absolute;
    left: -280px;
    top: 18px;
    transition: opacity 0.1s, left 0.6s;
  }

  &.open {
    width: 280px;

    & .children-link .sidebar-link {
      padding-left: 16px;
    }

    & .sidebar-text {
      opacity: 1;
      // display: block;
      position: initial;
      left: 48px;
      right: auto;
      transition: opacity 0.9s, left 0.6s;

    }
  }
}

.close_sidebar {
  padding-left: 78px !important;
  transition: padding 0.6s;
}

.open_sidebar {
  padding-left: 300px !important;
  transition: padding 0.6s;
}

.border-light {
  border-color: #465163 !important;
}

.border-grey2 {
  border-color: var(--grey2) !important;
}

.rounded-12 {
  border-radius: 12px !important;
}

.sidebar-link {
  color: var(--white);
  position: relative;
  z-index: 1;

  &.active {
    background: var(--grey9);
    text-decoration: none;
  }

  &:hover {
    background: var(--grey9);
    text-decoration: none;
    color: #fff;

    & .sidebar-icon {
      background: var(--grey9);
    }

    & .sidebar-icon img {
      filter: invert(0) grayscale(100%) brightness(200%) contrast(10);
    }
  }
}

.sidebar-icon {
  width: 48px;
  min-width: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  background: #354052;
}

.sidebar-link.active {
  color: #fff;

  .sidebar-icon {
    background: var(--grey9);
  }

  & .sidebar-icon img {
    filter: invert(0) grayscale(100%) brightness(200%) contrast(10);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: fixed;
  width: 100%;
  padding: 4px 48px;
  z-index: 10;
  transition: padding-left 0.6s;

  &.open {
    padding-left: 280px;
  }
}

.outline-none {
  outline: none;

  &:focus {
    outline: none;
  }
}

.h-32 {
  height: 32px;
}

.bg-light {
  background: #eff2f7;
}

.badge {
  border-radius: 2px;
  padding: 2px 4px;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #212529;
}

.badge-header {
  font-size: 9px;
  line-height: 1;
}

.badge-primary {
  background-color: #00719C;
  color: #fff;
}

.badge-success {
  background-color: #009731;
  color: #fff;
}

.badge-orange {
  background-color: var(--orange2);
  color: var(--white);
}

.badge-error {
  background-color: var(--red1);
  color: var(--white);
}

.badge-round {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
}

.badge:empty.badge-round {
  display: block !important;
}

.tooltip_container {
  z-index: 1;
  position: relative;
  // filter: drop-shadow(0px 6px 12px rgba(0, 147, 194, 0.12));
}

.tooltip_container .tooltip_text {
  visibility: hidden;
  display: none;
  background-color: white;
  color: #1c1c1c;
  text-align: center;
  font-weight: 300;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
  z-index: 1;
  line-height: 1.2;

  /* Position the tooltip text */
  position: absolute;
  top: calc(100% + 12px);
  right: 0;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip_container .tooltip_text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 50%;
  border-width: 14px;
  border-style: solid;
  border-color: transparent transparent rgba(153, 153, 153, 0.1) transparent;
}

.tooltip_container:hover {
  z-index: 1102;
  cursor: pointer;
}

.tooltip_container:hover .tooltip_text {
  visibility: visible;
  opacity: 1;
  display: block;
}

.tooltip_icon {
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("../images/tooltip.svg");
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  right: 0;
}

.tooltip_for_label {
  position: relative;
  height: 16px;
}

.font-size-12 {
  font-size: 12px;
  line-height: 16px;
}

.font-size-18 {
  font-size: 18px;
  line-height: 24px;
}

.font-weight-medium {
  font-weight: 500;
}

.table-bordered thead th, .table-bordered thead td {
  vertical-align: top;
}

.calendar-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.input-currency {
  position: absolute;
  right: 8px;
  top: 36px;
  font-size: 24px;
  color: #6c757d;
}

.filtered-table {
  margin-bottom: 0;
  //border-radius: 12px 12px 0 0;
  border: none;
  //border-radius: 10px;
  //-webkit-border-radius: 10px;
  //-moz-border-radius: 10px;
  //-khtml-border-radius: 12px 12px 0px 0px;
  ////border: 1px solid #000;
  //display: block;
  thead {
    box-shadow: 0 2px 8px 0 #00000014;
    border-top: 1px solid var(--grey1);

  }

  thead th {
    font-weight: 400;
    color: var(--grey5);
    font-size: 11px;
    text-wrap: balance;
  }

  th, td {
    border: none;
  }
}

.burger-button {
  transform: rotate(180deg);
  transition: 0.6s;
}

.open {
  & .burger-button {
    transform: rotate(0deg);
  }
}

.rotateArrow {
  transform: rotate(180deg);
  transition: 0.6s;

  &.active {
    transform: rotate(0deg);
  }
}

.sorted-icon {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 20px;
  height: 20px;
  //margin: 04px;
  cursor: pointer;
  background-image: url("./../images/sort-arrow-black.svg");
  opacity: .4;
  transition: opacity 0.3s;

  &:hover {
    opacity: .7;
  }

  &.asc {
    opacity: 1;
  }

  &.desc {
    opacity: 1;
    transform: rotate(180deg);
  }
}

.text-grey5 {
  color: var(--grey5);
}

.text-primary {
  color: var(--blue3) !important;

  &:hover {
    color: var(--blue4) !important;
  }
}

.text-underline {
  text-decoration: underline;
}

.btn {
  .settings-account {
    path {
      stroke: var(--blue4);
      stroke-width:1;
    }

  }

  &:hover .settings-account {
    path {
      stroke: var(--white);
    }
  }

  &:disabled .settings-account path {
    stroke: var(--grey5);
  }

}

.border-secondary {
  border-color: var(--grey3) !important;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.sidebar-marker-wrap {
  //border-radius: 4px;
  padding: 2px;
  background-color: var(--grey8);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.sidebar-marker {
  color: var(--white);
  padding: 0 6px 0 4px;

  &.active {
    color: var(--yellow);
    text-decoration: none;
    padding-left: 4px;
    background-color: var(--grey9);
  }

}

.simple_tooltip_text {
  z-index: 10000;
}

.files-load-wrap {
  width: 72px;
  height: 56px;
  border-radius: 6px;
  overflow: hidden;
  background: var(--grey1);
  display: flex;
  justify-content: center;
  align-items: center;


  & img {
    object-fit: cover;
    max-width: 100%;

  }
}
.screening-file{
  width: 300px;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
  background: var(--grey1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;


  & img {
    object-fit: cover;
    max-width: 100%;

  }
}

.hidden-input{
  display: none;
}

.self-reg-row{
  background-color: rgba(5, 113, 230, 0.1)!important;
  &:hover{
    background-color: rgba(5, 113, 230, 0.17)!important;
  }
}